import {
  call,
  put,
  select,
  take,
  fork,
  cancel,
  delay,
  apply,
  race,
} from 'redux-saga/effects';
import {
  updateStreamUrls,
  streamUrlsRequestError,
} from 'actions/stream';
import {
  StreamUrlRequest,
  DashUrlWorldLotteryRequest,
  LcrStreamUrlRequest,
  LcrDashUrlWorldLotteryRequest,
} from 'streams-api';
import {
  getUserId,
  getStreamRequestSettings,
  getWorldLotteryStreamId,
  getQueryStreamUrl,
  getStreamUrl,
  getWorldLotteryUrl,
  getChannelId,
  getLcrStreamUrl,
  getLcrWorldLotteryUrl,
  getIsOperator,
} from 'reducers/index';
import {
  POLL_STREAM_URLS,
  STREAM_IS_PLAYING,
  CANCEL_POLLING_STREAM_URLS,
  SET_VIDEO_BREAK,
  REMOVE_VIDEO_BREAK,
  PLAYER_AUTOPLAY_BLOCKED,
} from 'constants/actions';
import { log } from 'actions/logger';
import { sagaRunning } from './utilSagas';
import { DisplayError } from '../utils/LoggerItem';
import { isOldHostName } from '../utils/Url';

// TODO: rewrite to use same polling api as matchinfo
function* loadStreamUrls(matchId) {
  const qUrl = yield select(getQueryStreamUrl);
  if (qUrl) {
    yield put(updateStreamUrls([{
      url: qUrl,
    }]));
    return;
  }
  const userId = yield select(getUserId);
  const isOperator = yield select(getIsOperator);
  const channelId = yield select(getChannelId);
  let streamUrl = null;
  const streamBaseUrl = isOldHostName() ? yield select(getStreamUrl) : yield select(getLcrStreamUrl);

  const streamRequest = isOldHostName()
    ? new StreamUrlRequest(streamBaseUrl, matchId, userId, channelId)
    : new LcrStreamUrlRequest(streamBaseUrl, matchId, isOperator, userId);
  streamUrl = yield apply(streamRequest, streamRequest.get);

  const streamsArr = []; // array since it used to be both dash, smil and cmaf
  if (streamUrl && streamUrl.url) {
    streamsArr.push(streamUrl);
  }

  if (streamsArr.length) {
    yield put(updateStreamUrls(streamsArr));
  } else {
    yield put(streamUrlsRequestError('No available streams for current match.'));
  }
}

function* loadWorldLotteryStreamUrls() {
  const streamId = yield select(getWorldLotteryStreamId);
  let dashUrl = null;
  const dashBaseUrl = isOldHostName() ? yield select(getWorldLotteryUrl) : yield select(getLcrWorldLotteryUrl);
  const userId = yield select(getUserId);
  const isOperator = yield select(getIsOperator);

  const dashRequest = isOldHostName()
    ? new DashUrlWorldLotteryRequest(dashBaseUrl, streamId)
    : new LcrDashUrlWorldLotteryRequest(dashBaseUrl, isOperator, userId);
  dashUrl = yield call([dashRequest, dashRequest.get]);

  if ((!dashUrl) || (!dashUrl.url)) {
    yield put(streamUrlsRequestError('No available streams for world lottery.'));
    // Improvement: add console log message caught from streams-loader
  } else if (dashUrl.url) {
    yield put(updateStreamUrls([dashUrl]));
  }
}

function randomIntFromInterval(min, max, maxValue = 999999) {
  const rng = Math.floor(Math.random() * (max - min + 1) + min);
  return rng > maxValue ? maxValue : rng;
}

/*
  Keeps polling until STREAM_IS_PLAYING action is fired
*/
function* pollStreamUrls(id, worldLottery = false) {
  const streamRequestSettings = yield select(getStreamRequestSettings);
  // Back-off request settings
  const {
    maxAttempts,
    initialDelay,
    maxDelay,
    delta,
  } = streamRequestSettings;
  let nextDelay = initialDelay;

  for (let i = 0; i < maxAttempts; i++) {
    if (worldLottery) {
      yield call(loadWorldLotteryStreamUrls);
    } else {
      yield call(loadStreamUrls, id);
    }

    yield race([
      delay(randomIntFromInterval(nextDelay - 1000, nextDelay + 1000, maxDelay)),
      take(POLL_STREAM_URLS),
    ]);
    nextDelay *= delta;
  }

  // block here, not let it fork this saga again without match switch
  yield take(STREAM_IS_PLAYING);
}

function* monitorStreamsUrls(id, worldLottery = false) {
  let polling = null;

  for (;;) {
    const { type } = yield take([
      POLL_STREAM_URLS,
      STREAM_IS_PLAYING,
      CANCEL_POLLING_STREAM_URLS,
      REMOVE_VIDEO_BREAK,
      SET_VIDEO_BREAK,
      PLAYER_AUTOPLAY_BLOCKED,
    ]);

    const running = sagaRunning(polling);

    if (running) {
      if (type === STREAM_IS_PLAYING) {
        yield cancel(polling);
        polling = null;
      } else if (type === SET_VIDEO_BREAK) {
        yield cancel(polling);
        yield put(updateStreamUrls([]));
        polling = null;
      } else if (type === CANCEL_POLLING_STREAM_URLS) {
        // cancel monitoring
        yield cancel(polling);
        break;
      } else if (type === PLAYER_AUTOPLAY_BLOCKED) {
        const msg = new DisplayError(
          'LivePlayer Error',
          'Video Autoplay Blocked In Browser',
        );
        yield put(log(msg));
        yield cancel(polling);
      }
    } else if (type === POLL_STREAM_URLS || type === REMOVE_VIDEO_BREAK) {
      polling = yield fork(pollStreamUrls, id, worldLottery);
    }
  }
}
export default monitorStreamsUrls;
