import { convertToInt } from './helpers';

export const isOldHostName = () => false;

export const isNewHostName = () => true;

export const getUserIdFromUrl = () => {
  const regex = /client\/(\d+)/;
  const regexMatch = window.location.hash.match(regex);

  if (regexMatch) {
    const id = regexMatch[1];
    return convertToInt(id);
  }

  return null;
};

export const redirectCurrentHostToClient = userId => {
  window.location.href = `/#/client/${userId}`;
};

export const redirectToLogin = () => {
  window.location.href = '/#/login';
  window.location.reload(true);
};
export const redirectToForgotPassword = () => {
  window.location.href = '/#/forgot-password';
};
